import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class TareeqiProjectDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Tareeqi — Mario Mouris' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image tareeqi_project_bg_image"  data-black-overlay="9">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Tareeqi</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <p className="subtitle">Tareeqi, winner of GITEX best social impact startups in 2018, and was among Digest Africa’s top 50 startups to watch in 2019, brings you the solution you've been waiting for.</p>
                                        <p>Track the bus you or your kids are taking, get notified with every movement, and never miss the bus again!</p>
                                        
                                        <br />

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <ul>
                                                    <li><h4>Viral21 Project</h4></li>
                                                </ul>
                                            </div>

                                            <div className="port-view">
                                                <span>Features & Technologies</span>
                                                <ul>
                                                    <li><h4>RxSwift</h4></li>
                                                    <li><h4>RxJava</h4></li>
                                                    <li><h4>Android Jetpack</h4></li>
                                                    <li><h4>MVVM</h4></li>
                                                    <li><h4>Navigation & Maps</h4></li>
                                                    <li><h4>Navigation Coordinators</h4></li>
                                                    <li><h4>Unit Tests</h4></li>
                                                    <li><h4>UI Tests</h4></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="portfolio-thumb-inner mt--40">
                                        <div className="portfolio-images-grid">
                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-tareeqi/screenshot-1.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-tareeqi/screenshot-2.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-tareeqi/screenshot-3.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Related Work</span>
                                    <h2>More Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/my-work/ava-finance">
                                            <img src="/assets/images/portfolio/project-ava/poster.png" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/my-work/ava-finance">AVA Finance</a></h4>
                                        <span className="category">iOS App</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/my-work/drops">
                                            <img src="/assets/images/portfolio/project-drops/poster.png" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/my-work/drops">Drops</a></h4>
                                        <span className="category">iOS App</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default TareeqiProjectDetails;
