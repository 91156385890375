import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { CopyBlock, dracula } from "react-code-blocks";

class NavigationCoordinatorSwiftBlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Navigation and Coordinator Pattern in Swift' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image navigation_coordinator_swift_bg_image" data-black-overlay="8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Navigation and Coordinator Pattern in Swift</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />May 28, 2020</li>
                                        <li><FiUser />Mario Mouris</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>If you think about it, no application nowadays doesn’t include some form of navigation; unless it only consists of a single screen. So, we need an efficient way to handle moving the user from one place to the other and to pass data between screens in a reliable way. A way that doesn’t involve the view controller taking all the responsibility resulting in a Massive View Controller.</p>
                                        <p>All the logic we’ll discuss today applies to modal view controllers and navigation view controllers. It applies to almost any architecture pattern, as the Coordinator pattern involves a high level of separation so that the dependencies are not all over the view controller.</p>
                                        <p>With further ado, let’s get right into it and discuss the different option you have or handling navigation in Swift.</p>
                                        
                                        <h2>Pushing straight from the ViewController</h2>
                                        <p>This is usually how we first approach navigation when we start learning iOS development.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`class PostsListViewController: UITableViewController {
    // MARK: - Properties
    private var posts: [Posts]
    
    // MARK: - Methods
    override func tableView(_ tableView: UITableView, didSelectRowAt indexPath: IndexPath) {
        let post = posts[indexPath.row]
        let postDetailsVc = PostDetailsViewController(post: post)
        navigationController?.pushViewController(postDetailsVc, animated: true)
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>While this solution works fine and you won't be needing any thing more for really simple apps, this introduces 2 problems:</p>
                                        <ul>
                                            <li>The PostsViewController handles pushing the created view controller, which should not be its responsibility.</li>
                                            <li>The PostsViewController handles creating the PostDetailsViewController dependency, which causes tight coupling between the two view controllers.</li>
                                            <li>The PostsViewController acts as the UITableViewDataSource, which is guess what? Not its responsibility as well.</li>
                                        </ul>
                                        <p>Or even worse, imaging if you want to handle something like deep linking? That would be a nightmare. </p>
                                        <p>We’ll be addressing the first problem in this article. The second problem is already discussed in my <a href="/blog/advanced-dependency-injection-in-swift">Dependency Containers Article</a>. And about the third problem, I’ll approach that in an article in the near future.</p>
                                        <p>So, let’s discuss how we can extract the navigation logic into its own component to make our life easier.</p>

                                        <h2>The Coordinator Pattern</h2>
                                        <p>What is the coordinator pattern you may ask? The idea of the Coordinator pattern is to create a separate component which encapsulates the navigation logic and is responsible for the application’s flow management.</p>
                                        <p>The coordinator pattern allows view controllers to know nothing about their child view controllers, and to allow view controllers to be self contained.</p>
                                        <p>Each coordinator can have child coordinators. Ideally, every coordinator is responsible for handling a part of the application’s flow. Every coordinator knows absolutely nothing about its parent Coordinator, it can only start its child coordinators.</p>
                                        
                                        <h3>Let’s start by creating the protocol</h3>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`import UIKit

public typealias CoordinatorStartCompletionHandler = () -> ()

/**
 A coordinator is responsible for navigation between views.
 No view should be able to navigate on its own, that's a responsibility for its coordinator.
 It should have a start with an optional completion handler.
 */
protocol Coordinator {
    /// Starts the coordinator flow
    func start(completionHandler: CoordinatorStartCompletionHandler?)
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Simple, huh? All the coordinator protocol needs is a way to start itself. And optionally, I’ve gone ahead and added completion handler to know when it’s started successfully.</p>
                                        <p>Now what? Let's create a AppCoordinator class that conforms to the Coordinator pattern to hold all of our navigation logic.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`typealias PostsVcFactory = () -> PostsViewController
typealias PostDetailsVcFactory = (Post) -> PostDetailsViewController

/// Handles the app's flow
class AppCoordinator: Coordinator {
    
    // MARK: - Properties
    /// The navigation controller where all other views are built upon (The one set to window.rootViewController)
    lazy var rootVc: UINavigationController = UINavigationController()
    
    // MARK: - Factories
    private let postsVcFactory: PostsVcFactory
    private let postDetailsVcFactory: PostDetailsVcFactory
    
    // MARK: - Initializer
    init(postsVcFactory: @escaping PostsVcFactory,
         postDetailsVcFactory: @escaping PostDetailsVcFactory) {
        self.postsVcFactory = postsVcFactory
        self.postDetailsVcFactory = postDetailsVcFactory
    }
    
    // MARK: - Methods
    func start(completionHandler: CoordinatorStartCompletionHandler?) {
        goToPostsVc()
    }
    
    /// Navigates to the posts view controller
    func goToPostsVc() {
        let postsVc = postsVcFactory()
        rootVc.pushViewController(postsVc, animated: false)
    }
    
    /// Navigates to the post details view controller
    func goToPostDetailsVc(post: Post) {
        let postDetailsVc = postDetailsVcFactory(post)
        rootVc.pushViewController(postDetailsVc, animated: true)
    }
}
view raw`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>If this looks a little overwhelming to you, I completely understand. It's a lot.</p>
                                        <p>First, I define 2 view controller Factories, they are simply a typealias to closure that creates the needed view controllers. I've went with this implementation to eliminate the need for the Coordinator to know about its dependencies. This goes really well with the Dependency Injection Article we've discussed earlier. I'll show an example of course of how it's injected in this article.</p>
                                        <p>Secondly, inside of the AppCoordinator itself, we lazely create a rootVc which is essentially the UINavigationController that handles all the navigation. It's also the one added to window.rootViewController in the AppDelegate (More on that later)</p>
                                        <p>And the rest is really simply, they are just some straight forward functions to start the coordinator and navigate through the app’s flow.</p>
                                        
                                        <h3>Want to take it a step further?</h3>
                                        <p>Why should our ViewController even know about our AppCoordinator? Why not communicate through a protocol? That's why we'll create a protocol called AppNavigator which the AppCoordinator will conform to. And that would be the thing injected into our view controller.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`protocol AppNavigator {
    func navigate(to view: NavigationView)
}

enum NavigationView: Equatable {
    case postsList
    case postDetails(post: Post)
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>And finally, we'll need to make the AppCoordinator conforom to the AppNavigator protocol.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`extension AppCoordinator: AppNavigator {
    func navigate(to view: NavigationView) {
        switch view {
        case .postsList:
            goToPostsVc()
        case .postDetails(let post):
            goToPostDetailsVc(post: post)
        }
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />

                                        <h3>Wiring things up</h3>
                                        <p>Now, let’s create an instance of the AppCoordinator in our AppDelegate like the following:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`class AppDelegate: UIResponder, UIApplicationDelegate {

    // MARK: - Properties
    private let dependencyContainer = AppDependencyContainer()
    private var coordinator: AppCoordinator?
    var window: UIWindow?
    
    // MARK: - Methods
    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
        
        // Setup coordinator
        coordinator = AppCoordinator(postsVcFactory: dependencyContainer.makePostsViewController,
                                     postDetailsVcFactory: dependencyContainer.makePostDetailsViewController(post:))
        
        // Setup window
        window = UIWindow(frame: UIScreen.main.bounds)
        window?.makeKeyAndVisible()
        window?.rootViewController = coordinator?.rootVc
        
        // Start coordinator
        coordinator?.start()
        
        return true
    }    
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Now, let's see how the example we first presented looks like now with our new coordinator.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`class PostsListViewController: UITableViewController {
    // MARK: - Properties
    private var posts: [Posts]
    private let navigator: AppNavigator
    
    // MARK: - Methods
    override func tableView(_ tableView: UITableView, didSelectRowAt indexPath: IndexPath) {
        let post = posts[indexPath.row]
        navigator.navigate(to: .postDetails(post: post)
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Neat, right? Now, as you can see, the PostsViewController knows absolutely nothing about PostDetailsViewController. It even doesn't know about the AppCoordinator, as we've injected the AppNavigator protocol instead of the implementation.</p>
                                        <h2>Conclusion</h2>
                                        <p>Today, we’ve made navigation a lot easier. Don’t be tempted to think that we’re introducing unneeded complexity. Although this is indeed more work, but you get a cleaner, simpler way of moving between your multiple view controllers. And we also stripped away a big responsibility from our view controller.</p>
                                        <p>Hope you enjoyed today’s article and learnt something new from it.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default NavigationCoordinatorSwiftBlogDetails;