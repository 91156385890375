import React, { Component } from "react";

class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="section-title text-left mb--50">
                            <h2 className="title">{this.props.contactTitle}</h2>
                            <p className="description">Feel free to reach out if you’re looking for an engineer, have a question, or just want to connect.</p>
                            <p> <a href="mailto:mario@mariomouris.me">mario@mariomouris.me</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;